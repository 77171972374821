import { Song } from 'types/releaseTypes';

const Podroznik: Song = Object.freeze({
    title: 'Podróżnik',
    author: 'Fisheclectic',
    isCover: true,
    tempo: 120,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2020, 8, 24),
    youtubeLink: 'https://youtu.be/RYjX93X2S4I',
  });

export default Podroznik;