import { textify } from 'store/text';
import { Song } from 'types/releaseTypes';

const NoLongerSlaves: Song = Object.freeze({
    title: 'No longer slaves',
    extraText: '(Nie muszę się więcej bać)',
    author: 'Bethel Music',
    isCover: true,
    tempo: 80,
    meter: '4/4',
    key: 'F',
    releaseDate: new Date(2020, 4, 25),
    youtubeLink: 'https://youtu.be/FtaJKwuaGw4',
    textFile: 'no-longer-slaves',
    chordsFile: 'no-longer-slaves-F',
    textLeft: textify(`
      Ty przynosisz mi zwycięstwa pieśń
      Ty dotykasz Słowem Swym 
      Dajesz wolność mi z sideł wokół mnie 
      Więc znika każdy lęk

      Wiem nie muszę się więcej bać
      Bo jestem dzieckiem Twym 
      Wiem nie muszę się więcej bać
      Bo jestem dzieckiem Twym

      Ty wybrałeś mnie u początku dni 
      Wyszeptałeś imię me 
      Jestem dzieckiem Twym, pokochałeś mnie 
      W moich żyłach Twoja krew

      Wiem nie muszę się więcej bać
      Bo jestem dzieckiem Twym 
      Wiem nie muszę się więcej bać
      Bo jestem dzieckiem Twym
    `),
    textRight: textify(`
      W ramionach Ojca 
      Odnalazłem pokój 
      W ramionach Ojca 
      Wszystko możliwe jest 
      On sam nas wykupił, 
      Zmazał winę
      Nazwał nas Swoimi dziećmi 
      Dziś możemy śpiewać

      Bo na Twój głos dziś mogę przejść przez morze 
      W miłości tonie cały strach 
      Wyzwalasz mnie i mogę wyznać, że 
      Ja jestem dzieckiem Twym
    `)
  });

export default NoLongerSlaves;