import { Container } from 'react-bootstrap';

import ImageAndTextBlock from './components/ImageAndTextBlock';
import { imageAndTextData } from 'data/imageAndTextData';
import { CenterMargin } from 'components/Margin';
import AboutXYU from './components/AboutXYU';
import Music from './components/Music';
import 'styles/pages/MainPage.scss';
import { ALBUMS, LATEST_ALBUM } from 'data/releases/albums';
import NewestReleaseBox from 'components/NewestReleaseBox';

function MainPage() {
  const backgroundImage = 'images/background/background_new-album.jpg'
  return (
    <>
    <NewestReleaseBox release={ALBUMS[LATEST_ALBUM]} backgroundImage={backgroundImage}  color='secondary'/>
    <CenterMargin>
      
      <AboutXYU />
      <Music />
      <Container className='my-margin content-container' fluid>
        {Object.keys(imageAndTextData).map((key, index) => (
          <ImageAndTextBlock key={`${key}_img_and_text`} data={imageAndTextData[key]} isTextLeft={index % 2 === 0} />
        ))}
      </Container>
    </CenterMargin>
    </>
    
  );
}

export default MainPage;
