import { Song } from "types/releaseTypes";
import { textify } from 'store/text';

const Selah: Song = Object.freeze({
    title: 'Selah',
    extraText: '(Wołanie)',
    author: 'Miłosz Rokacz',
    isCover: false,
    tempo: NaN,
    meter: '4/4',
    key: 'F',
    releaseDate: new Date(2021, 12, 5),
    spotifyLink: 'https://open.spotify.com/track/1BWCuJR19kA9gEWeqkCHBK?si=601d1bf19caf4350',
    textFile: 'selah',
    chordsFile: 'selah-F',
    textLeft: textify(`
      WSPOMINAM DAWNE DNI
      WSPANIAŁE DZIEŁA TWOJE
      CZYNY TWOICH RĄK WSPOMINAM
      WYCIĄGAM RĘCE DO CIEBIE

      DUSZA MOJA PRAGNIE CIĘ
      TAK JAK ZIEMIA BEZWODNA
      PRZYJDŹ,
      NAWODNIJ SUCHĄ ZIEMIĘ, PANIE
      PRZYJDŹ
      JAK OŻYWCZY DESZCZ
    `)
  });

export default Selah;