import { Song } from 'types/releaseTypes';

const HallelujahHereBelow: Song = Object.freeze({
    title: 'Hallelujah here below',
    extraText: '(Alleluja płynie stąd)',
    author: 'Elevation Worship',
    isCover: true,
    tempo: 63,
    meter: '3/4',
    key: '..',
    releaseDate: new Date(2021, 3, 11),
    youtubeLink: 'https://youtu.be/Kc0OkJV1C4w',
    spotifyLink: 'https://open.spotify.com/track/78sLugXPB0RKhN5fLDr79X?si=271dd4c3ae654265',
  });

export default HallelujahHereBelow;