import { ImageAndTextBlockData } from '../types/componentData';
import { IDictionary } from '../types/dictionary';
import { scrollLinks } from './pageLinks';
import churchInfo from './contactInfo';
import { KeyedBreak } from 'store/text';

const payment = `${churchInfo.expandedName}
ul. ${churchInfo.street} ${churchInfo.houseNumber}
${churchInfo.postalCode} ${churchInfo.city} 69
${churchInfo.bankAccountNumber}
Tytuł przelewu: XY Uwielbienie`
  .split('\n')
  .flatMap((line) => [line, KeyedBreak()]);

export const imageAndTextData: IDictionary<ImageAndTextBlockData> = {
  OurHome: {
    id: scrollLinks.OurHome.ref,
    title: scrollLinks.OurHome.title,
    text: [
      'XY Uwielbienie to muzyka i treści płynące ',
      <b key='heat_of_the_church_bold'>{`z serca ludzi z kościoła ${churchInfo.expandedName} w ${churchInfo.cityConjugated}. `}</b>,
      'To kościół, który w oparciu o naukę Biblii i z mocą Ducha Świętego ' +
        'wspiera ludzi w nawiązywaniu osobistej relacji z Bogiem. Bogiem, który w swojej ' +
        'miłości i mocy ma dla każdego cudowny plan.',
    ],
    imageUrl: 'images/side/section_our-home.jpg',
  },
  Jezus: {
    id: 'Jesus',
    title: 'Jezus',
    text:
      'Jezus jest sensem i celem, dla którego się spotykamy i dla którego ' +
      'żyjemy. Jego życie, krzyż i zmartwychwstanie są Bożą odpowiedzią na ' +
      'grzech i śmierć.',
    expandedData: {
      title: 'Jezus jest sensem i celem',
      text: [
        'dla którego się spotykamy i dla którego żyjemy. Jego życie, krzyż i zmartwychwstanie są Bożą odpowiedzią na grzech i śmierć.',
        KeyedBreak(),
        KeyedBreak(),
        'Bóg bowiem tak bardzo ukochał świat, że dał swego Jedynego Syna, aby każdy, ' +
          'kto w Niego wierzy, nie zginął, ale miał życie wieczne. Bóg nie posłał swego ' +
          'Syna na świat, aby wydał On na świat wyrok, lecz aby świat został przez Niego zbawiony.',
        KeyedBreak(),
        <b key='J3:16_bold'>Ewangelia Jana 3:16-17</b>,
        KeyedBreak(),
        KeyedBreak(),
        'Każdy z nas zgrzeszył, a każdy grzech zasługuje na śmierć. Żadne ' +
          'uczynki ani działania nie są w stanie nikogo zbawić, ale Bóg okazał ' +
          'nam łaskę – swoją niezasłużoną przychylność. To ofiara Jezusa na ' +
          'krzyżu jest zapłatą za każdy grzech.',
        KeyedBreak(),
        KeyedBreak(),
        'Gdyż z łaski jesteście zbawieni, przez wiarę. Nie jest to waszym ' +
          'osiągnięciem, ale darem Boga. Nie stało się to dzięki uczynkom, aby ' +
          'się ktoś nie wynosił.',
        KeyedBreak(),
        <b key='Ef2:8-9_bold'>List do Efezjan 2:8-9</b>,
        KeyedBreak(),
        KeyedBreak(),
        <b key='GoodNews_bold'>Dobra wiadomość jest taka:</b>,
        KeyedBreak(),
        'Bo jeśli ustami wyznasz, że Panem jest Jezus, i uwierzysz ' +
          'w swym sercu, że Bóg wzbudził Go z martwych, będziesz zbawiony.',
        KeyedBreak(),
        <b key='Rzm10:9_bold'>List do Rzymian 10:9</b>,
      ],
    },
    imageUrl: 'images/side/section_jesus.jpg',
  },
  FindUs: {
    id: scrollLinks.FindUs.ref,
    title: scrollLinks.FindUs.title,
    text: [
      <b key='when_bold'>W każdą pierwszą środę miesiąca,</b>,
      ` podczas Wieczorów Uwielbienia, wywyższamy Boga wspólnie z naszym kościołem ${churchInfo.name}. ` +
        'To prawdziwy czas odpoczynku w Bożej Obecności. Dołącz do nas!',
      KeyedBreak(),
      KeyedBreak(),
      'Zapraszamy też na nasz kanał na YT. Pracujemy nad tym, by nagrań było coraz więcej!',
    ],
    imageUrl: 'images/side/section_find-us.jpg',
  },
  Support: {
    id: scrollLinks.Support.ref,
    title: scrollLinks.Support.title,
    text: [
      'Przede wszystkim potrzebujemy Twojej modlitwy. To najcenniejsze, co ' +
        'możesz nam dać, byśmy mogli się rozwijać i realizować marzenia, jakie ' +
        'Bóg wkłada w nasze serca.',
      KeyedBreak(),
      KeyedBreak(),
      'Niemniej ',
      <b key='miliony_monet_needed_bold'>
        realizacja wysokiej jakości nagrań wymaga inwestycji dużej ilości pieniędzy.{' '}
      </b>,
      'Jeśli chcesz, możesz wesprzeć finansowo XY Uwielbienie!',
      KeyedBreak(),
      KeyedBreak(),
      ...payment,
    ],
    imageUrl: 'images/side/section_support.jpg',
  },
};
