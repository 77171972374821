import { Col, Container, Image, Row } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';

import { extLinks, scrollLinks } from '../data/pageLinks';
import FollowUsIconsRow from './FollowUsIconsRow';
import '../styles/components/PageFooter.scss';
import '../styles/components/ScrollLink.css';
import churchInfo from '../data/contactInfo';
import ScrollLink from './ScrollLink';

const mapKeysToGrid = (keys: (keyof typeof scrollLinks)[]) => keys.map((key) => {
  const link = scrollLinks[key];
  return (
    <div key={`page_${key}_table_data`}>
      <ScrollLink link={link} justify='start' color='light' />
    </div>
  );
})

function PageFooter() {
  const containerRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(100);
  const [infoHeight, setInfoHeight] = useState(68);

  const handleResize = () => {
    if (containerRef.current) {
      setHeight(containerRef.current.getBoundingClientRect().width / 4);
    } else {
      setHeight(100);
    }
    if (infoRef.current) {
      setInfoHeight(infoRef.current.clientHeight);
    } else {
      setInfoHeight(100);
    }
  };

  useEffect(() => {
    if (infoRef.current) setInfoHeight(infoRef.current.clientHeight);
  }, [infoRef]);

  useEffect(() => {
    if (containerRef.current) setHeight((containerRef.current.getBoundingClientRect().width ?? 400) / 4);
  }, [containerRef]);

  window.addEventListener('resize', handleResize);

  return (
    <Container ref={containerRef} className='text-light no-gutters bounded_container my-margin' fluid>
      <Row className='flex-wrap-reverse justify-content-center'>
        <div className='footer-image-col'>
          <Image
            className=''
            src='/images/logo-white.png'
            alt='XYU logo'
            style={{ height: `calc(${height}px - 3em`, width: 'unset', paddingRight: '3em' }}
          />
        </div>
        <Col className='link_grid'>
          {mapKeysToGrid(['XYU', 'FindUs'])}
          <div className='icons_label'>Obserwuj Nas</div>
          {mapKeysToGrid(['Music', 'Support'])}
          <FollowUsIconsRow />
          {mapKeysToGrid(['OurHome'])}
          <div>
            <a
                style={{ color: `var(--bs-light)` }}
                key='page_Contact_link'
                className='d-flex justify-content-start scroll_link active'
                href={extLinks.Contact.ref}
              >
              {extLinks.Contact.title}
            </a>
          </div>
        </Col>
      </Row>
      <Row className='row-legal'>
        <Col><p>XY Uwielbienie<br/>{new Date().getFullYear()}</p></Col>
        <Col style={{ height: 'fit-content' }}>
          <p ref={infoRef} style={{ textAlign: 'end', width: 'max-content', paddingLeft: '1.7em' }}>
            {churchInfo.expandedName}
            <br />
            {`ul. ${churchInfo.street} ${churchInfo.houseNumber}, ${churchInfo.postalCode} ${churchInfo.city}`}
          </p>
          <Image
            src='images/logo-poludnia-biale.svg'
            alt='SChP logo'
            style={{ height: infoHeight / 1, paddingLeft: '1em', width: 'unset' }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default PageFooter;
