import { Song } from "types/releaseTypes";
import { textify } from 'store/text';

const CzekamNaCiebie: Song = Object.freeze({
    title: 'Czekam na Ciebie',
    author: 'Tymek Stokarski',
    isCover: false,
    tempo: 96,
    meter: '4/4',
    key: 'F',
    releaseDate: new Date(2019, 4, 18),
    youtubeLink: 'https://youtu.be/IXE5Ywtuc4I',
    spotifyLink: 'https://open.spotify.com/track/0OfbWgtvqGAv6q2mJGSRT0?si=2616e6b9ee2a44cd',
    chordsFile: 'czekam-na-ciebie-F',
    textFile: 'czekam-na-ciebie',
    textLeft: textify(`
      WZIĄŁEŚ ZA NAS CAŁY STRACH
      KAŻDY WSTYD I KAŻDY BÓL
      PRZEJĄŁEŚ SAM
      CHOCIAŻ NIKT NIE BYŁ TEGO WART
      KAŻDĄ WINĘ I KAŻDY GRZECH
      TAM NA KRZYŻU PRZYBIŁEŚ TY
      SZEDŁEŚ SAM
      BY SWĄ ŁASKĘ POKAZAĆ NAM

      JESTEM TU
      CZEKAM NA CIEBIE
      ZANIM PRZYJDZIESZ ZNÓW
      CHCĘ POZNAĆ CIĘ LEPIEJ
      JESTEM JUŻ
      WOLNY OD GRZECHU
      MOGĘ ŚPIEWAĆ TOBIE, ŻE
      NALEŻĘ DO CIEBIE

      WSZELKA CHWAŁA MOC I CZEŚĆ
      TOBIE NALEŻĄ SIĘ
      W TOBIE MAM NAJDROŻSZY SKARB -
      MOJE IMIĘ W SERCU TWYM
    `)
  });

export default CzekamNaCiebie;