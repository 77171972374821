import { IDictionary } from '../types/dictionary';

export type LinkRef = {
  ref: string;
  title: string;
};

export const scrollLinks: IDictionary<LinkRef> = {
  XYU: {
    ref: '/#XYUwielbienie',
    title: 'XY Uwielbienie',
  },
  Music: {
    ref: '/#Music',
    title: 'Muzyka',
  },
  OurHome: {
    ref: '/#OurHome',
    title: 'Nasz dom',
  },
  FindUs: {
    ref: '/#FindUs',
    title: 'Znajdź nas',
  },
  Support: {
    ref: '/#Support',
    title: 'Wesprzyj',
  },
};
export const extLinks: IDictionary<LinkRef> = {
  Contact: {
    ref: 'https://www.facebook.com/xyuwielbienie/about',
    title: 'Kontakt',
  },
  SCHPol: {
    ref: 'https://schpoludnie.pl/',
    title: 'SCH Południe',
  },
};
