import { Song } from 'types/releaseTypes';

const NewWine: Song = Object.freeze({
    title: 'New wine',
    extraText: '(Nowe wino)',
    author: 'Hillsong Worship',
    isCover: true,
    tempo: 70,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2020, 10, 26),
    youtubeLink: 'https://youtu.be/2hXt-f0-ars',
  });

export default NewWine;