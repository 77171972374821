import { Song } from 'types/releaseTypes';

const YouDeserveIt: Song = Object.freeze({
    title: 'You deserve it',
    extraText: '(Tobie chwała)',
    author: 'JJ Hairston, Youthful Praise',
    isCover: true,
    tempo: 160,
    meter: '6/8',
    key: '..',
    releaseDate: new Date(2022, 6, 21),
    youtubeLink: 'https://youtu.be/NKtbxmnEBl4',
  });

export default YouDeserveIt;