import { Song } from 'types/releaseTypes';

const ItIsSo: Song = Object.freeze({
    title: 'It is so',
    extraText: '(Kiedy mówisz)',
    author: 'Elevation Worship',
    isCover: true,
    tempo: 147,
    meter: '6/8',
    key: '..',
    releaseDate: new Date(2020, 10, 20),
    youtubeLink: 'https://youtu.be/LtJ_YWsqRec',
  });

export default ItIsSo;