import { Route, Routes } from 'react-router-dom';

import PageFooter from './components/PageFooter';
import { BlackMargin } from './components/Margin';
import MyNavbar from './components/MyNavbar';
import './styles/customTheme.sass';
import Pages from './pages';

function App() {
  return (
    <>
      <MyNavbar>
        <div style={{ paddingTop: 'var(--navbar-height, 126px)' }}>
          <Routes>
            <Route path='/' element={<Pages.MainPage />} />
            <Route path='/songs' element={<Pages.DiscographyPage />} />
            <Route path='/songs/album/:albumId' element={<Pages.AlbumPage />} />
            <Route path='/songs/album/:albumId/:songId' element={<Pages.SongPage />} />
            <Route path='/songs/single/:songId' element={<Pages.SongPage />} />
          </Routes>
        </div>
      </MyNavbar>
      <BlackMargin>
        <PageFooter />
      </BlackMargin>
    </>
  );
}

export default App;
