import { Song } from "types/releaseTypes";
import { textify } from 'store/text';

const UfacBede: Song = Object.freeze({
    title: 'Ufać będę',
    author: 'Patrycja Brzozowska',
    isCover: false,
    tempo: 68,
    meter: '4/4',
    key: 'B',
    releaseDate: new Date(2020, 11, 16),
    youtubeLink: 'https://youtu.be/FY9BDqaUJJE',
    spotifyLink: 'https://open.spotify.com/track/0U2VA4uyHhSvhD4jyxkF8F?si=4e9055c01e1f4aff',
    textLeft: textify(`
      UFAĆ BĘDĘ ZAWSZE
      IŚĆ ZA SŁOWEM TWYM
      WIERZYĆ OBIETNICOM
      KTÓRE DAŁEŚ MI

      NAWET GDY SIĘ ZDAJE
      ŻE CORAZ DALEJ CEL
      TY PODNOSISZ MNIE
      NADAJESZ SENS
    `),
    textRight: textify(`
      NIE WIEM JUŻ CO TO STRACH
      MOJE SERCE JEST SPOKOJNE
      JESTEŚ TU, BLISKO TAK
      KOCHASZ MNIE

      DOKĄD IŚĆ MÓWISZ MI
      TWA OBECNOŚĆ TO ODPOWIEDŹ
      MOGĘ W NIEJ ZANURZYĆ SIĘ
      ZNALEŹĆ SENS
    `)
  });

export default UfacBede;