import NewestReleaseBox from "components/NewestReleaseBox";
import ReleaseBox from "components/ReleaseBox";
import { ALBUMS, LATEST_ALBUM } from "data/releases/albums";
import { singles } from "data/releases/singles";
import { useCallback } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { AlbumImage, prepareUrl } from "store/url";
import 'styles/pages/DiscographyPage.sass';
import { Single, Album } from "types/releaseTypes";

const backgroundImage = 'images/background/background_new-song.jpg';

function DiscographyPage() {
  const navigate = useNavigate();
  const newSingle = singles.proszePowiedzPanie;
  const handleAlbumClick = useCallback(() => {
    navigate(prepareUrl(ALBUMS[LATEST_ALBUM]));
  }, [navigate])

  return (
    <>
    <NewestReleaseBox backgroundImage={backgroundImage} color='secondary' release={newSingle}/>
    <Container className="discography my-margin" fluid>
      <h1 className="discography__title">Nasza twórczość</h1>
      <hr/>
      <div className="new-album-box">
        <AlbumImage src={ALBUMS[LATEST_ALBUM].image} alt={`${ALBUMS[LATEST_ALBUM].title} cover`} className="new-album-box__image" />
        <div className="new-album-box__side">
          <div>
            <h4>Nowa płyta</h4>
            <h1>Południe</h1>
          </div>
          <Button variant='outline-dark' onClick={handleAlbumClick}>
            Więcej
        </Button>
        </div>
      </div>
      <h1 className="discography__title">Albumy</h1>
      <hr/>
      <div className="discography__list">
        {Object.values(ALBUMS).filter((a) => a.title !== ALBUMS[LATEST_ALBUM].title).map((release: Single | Album) => <ReleaseBox key={`${release.title}_release_box`} release={release}/>)}
      </div>
      <h1 className="discography__title">Utwory</h1>
      <hr/>
      <div className="discography__list">
        {Object.values(singles).map((release: Single | Album) => <ReleaseBox key={`${release.title}_release_box`} release={release}/>)}
      </div>
    </Container>
    </>
    
  );
}

export default DiscographyPage;
