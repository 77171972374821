import { Song } from 'types/releaseTypes';

const UciszSie: Song = Object.freeze({
    title: 'Ucisz się',
    author: 'Fisheclectic',
    isCover: true,
    tempo: 76,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2021, 1, 18),
    youtubeLink: 'https://youtu.be/sjsKiUCx2Zs',
    spotifyLink: 'https://open.spotify.com/track/0f1SkVZCJHMojL5eOaLmmL?si=47eba96bdad14452',
  });

export default UciszSie;