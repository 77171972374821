import React, { useState } from 'react';
import { Navbar, Container, Nav, Image, Offcanvas } from 'react-bootstrap';
import { Turn as Hamburger } from 'hamburger-react';
import { X } from 'react-bootstrap-icons';

import { extLinks, scrollLinks } from '../data/pageLinks';
import '../styles/components/MyNavbar.scss';
import ScrollLink from './ScrollLink';
import '../styles/customTheme.sass';
import { useNavigate } from 'react-router-dom';

function MyNavbar({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleHide = () => setMenuOpen(false);

  return (
    <>
      <Navbar fixed='top' style={{ width: '100%' }} className='bg-secondary'>
        <Container className='align-content-between navbar-container'>
          <Image
            className='header__image'
            src='/images/logo-black.png'
            alt='XYU logo'
            onClick={() => navigate('/')}
          />
          <Nav className='header__navbar' style={{ fontWeight: 'bold' }}>
            {['Music', 'XYU'].map((key) => {
              const link = scrollLinks[key];
              return <ScrollLink key={`page_${key}_link`} link={link} onClick={handleHide} />;
            })}
            <Nav.Link
              className='d-flex justify-content-end nav-link active'
              href={extLinks.Contact.ref}
              style={{ padding: `0 0.5em` }}
            >
              {extLinks.Contact.title}
            </Nav.Link>
          </Nav>
          <Navbar.Offcanvas
            show={isMenuOpen}
            onHide={handleHide}
            id='offcanvasNavbar-expand-false'
            aria-labelledby='offcanvasNavbarLabel-expand-false'
            placement='end'
          >
            <Offcanvas.Header className='justify-content-end' style={{ height: 'var(--navbar-height)' }}>
              <button
                onClick={handleHide}
                type='button'
                style={{
                  border: '0px',
                  padding: '0px',
                  width: '48px',
                  height: '48px',
                  backgroundColor: 'transparent',
                }}
              >
                <X size='48' />
              </button>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav style={{ fontWeight: 'bold', fontSize: '20px' }}>
                {Object.keys(scrollLinks).map((key) => {
                  const link = scrollLinks[key];
                  return <ScrollLink key={`page_${key}_link`} link={link} onClick={handleHide} />;
                })}
                {Object.keys(extLinks).map((key) => {
                  const link = extLinks[key];
                  return (
                    <Nav.Link
                      style={{ padding: '0px 0.6em' }}
                      key={`page_${key}_link`}
                      className='d-flex justify-content-end'
                      href={link.ref}
                      onClick={handleHide}
                    >
                      {link.title}
                    </Nav.Link>
                  );
                })}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <Hamburger toggled={isMenuOpen} toggle={setMenuOpen} direction='left' />
        </Container>
      </Navbar>
      {children}
    </>
  );
}

export default MyNavbar;
