import { Col, Row, Container } from 'react-bootstrap';

import FollowUsIconsRow from 'components/FollowUsIconsRow';
import TextColumnWithTitle from './TextColumnWithTitle';
import 'styles/components/MainPage/AboutXYU.scss';
import { scrollLinks } from 'data/pageLinks';
import aboutXYU from 'data/aboutXYU';

function AboutXYU() {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const imageUrl = require(`assets/${aboutXYU.imageUrl}`);
  return (
    <Container id={scrollLinks.XYU.ref.split('#')[1]} className='xyu-container no-gutters my-margin' fluid>
      <Row style={{ width: '100%', margin: 0 }}>
        <TextColumnWithTitle bgColor='light' fgColor='dark' data={aboutXYU.text} width='col-xyu-text' isLeft />
        <Col className='col-image'>
          <Row className='black-border'>
            <p style={{ width: 'max-content', padding: '0', margin: '0' }}>Obserwuj Nas</p>
            <FollowUsIconsRow />
          </Row>
          <img className='xyu-image' src={imageUrl} alt="people worshipping" />
        </Col>
      </Row>
    </Container>
  );
}

export default AboutXYU;
