import { textify } from "store/text";
import { Song } from "types/releaseTypes";

const MoimPokojem: Song = Object.freeze({
    title: 'Moim pokojem',
    author: 'Justyna Glińska',
    isCover: false,
    tempo: 168,
    meter: '4/4',
    key: 'F',
    releaseDate: new Date(2021, 12, 5),
    spotifyLink: 'https://open.spotify.com/track/0zy5Sjx1Yki9NeMPyuEtRX?si=3f17884019044f9c',
    chordsFile: 'moim-pokojem-F',
    textFile: 'moim-pokojem',
    textLeft: textify(`
    GDY PRZEDE MNĄ JEST PUSTKA
    ON ZA RĘKĘ TRZYMA MNIE
    CHOCIAŻ CZASEM SIĘ BOJĘ  
    ON POWTARZA “UFAJ MI”
    WOKÓŁ BURZA SZALEJE
    ON UCISZA SERCE ME
    I W CIEMNOŚCI DOOKOŁA
    ŚWIATŁEM SWYM ROZPRASZA MROK
    
    MOIM POKOJEM
    SIŁĄ, GDY SŁABNĘ  
    JEST MÓJ JEZUS
    MÓJ JEZUS
    MYM PEWNYM GRUNTEM
    I BEZPIECZEŃSTWEM
    JEST MÓJ JEZUS
    MÓJ JEZUS

    JEGO MIŁOŚĆ JEST SIŁĄ
    NAWET ŚMIERĆ NIE POKONA JEJ
    JEGO PLAN JEST NAJLEPSZY
    ZA ŻADNYM INNYM NIE CHCĘ IŚĆ
    POKAZUJE SWĄ WIERNOŚĆ
    NA KAŻDYM KROKU WIDZĘ JĄ
    JEGO MOC PONAD WSZYSTKIM
    NIC NIE MOŻE DORÓWNA Ć JEJ

    BURZYSZ KAŻDY MUR
    ABY BYĆ BLISKO MNIE
    PAMIĘTASZ O MNIE, GDY
    UPADAM I GUBIĘ SIĘ
    ŁAMIESZ KŁAMSTWA, GDY
    WIĄŻĄ MOJE SERCE
    MĄ PRAWDĄ JESTEŚ TY
    TA PRAWDA UWALNIA MNIE
    `)
  });

export default MoimPokojem;