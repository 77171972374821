import '../styles/components/ScrollLink.css';
import { LinkRef } from '../data/pageLinks';

function ScrollLink({
  link,
  onClick,
  justify,
  color,
}: {
  link: LinkRef;
  onClick?: () => void;
  justify?: 'center' | 'end' | 'start';
  color?: 'light' | 'dark' | 'primary' | 'secondary';
}) {
  return (
    <a
      href={link.ref}
      className={`d-flex justify-content-${justify ?? 'end'} scroll_link`}
      onClick={onClick}
      style={{ color: `var(--bs-${color ?? 'dark'})` }}
    >
      {link.title}
    </a>
  );
}

export default ScrollLink;
