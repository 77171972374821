import { ALBUMS } from "data/releases/albums";
import { useParams, useNavigate } from 'react-router-dom';
import { AlbumImage, getAlbumFromId, titleToUrl } from "store/url";
import { variantSize } from "./MainPage/components/TextColumnWithTitle";
import 'styles/pages/AlbumPage.scss';
import { Song } from "types/releaseTypes";
import NotFoundPage from "./NotFoundPage";
import { useMemo } from 'react';

const conjugate = (count: number) => {
  if(count === 1) {
      return '1 utwór';
  }
  if(count <= 4) {
    return `${count} utwory`;
  }
  return `${count} utworów`;
}

function AlbumPage() {
  const { albumId } = useParams();
  const navigate = useNavigate()
  const myAlbum = getAlbumFromId(albumId);
  const handleSongClick = (song: Song) => navigate(`/songs/album/${albumId}/${titleToUrl(song)}`);
  const AlbumButton = useMemo(() => {
    let buttonText;
    let href;
    if(myAlbum === undefined) return null;
    
    if(ALBUMS[myAlbum].spotifyLink) {
      buttonText = 'Spotify';
      href = ALBUMS[myAlbum].spotifyLink;
    } else if (ALBUMS[myAlbum].youtubeLink) {
      buttonText = 'YouTube';
      href = ALBUMS[myAlbum].youtubeLink;
    }
    if(buttonText === undefined) return null;
    return (
    <a className='btn btn-outline-dark' href={href} role="button" target="_blank" rel='noreferrer'>
            {buttonText}
    </a>
  )}, [myAlbum])
  if(!myAlbum) {
    return <NotFoundPage/>;
  }
  return (
    <div className='my-margin album__container' style={{ padding: `0.5rem ${variantSize(6.8)}` }}>
      <p className='title'><span className='text-weight-light'>Album</span> {ALBUMS[myAlbum].title}</p>
      <hr className='bg-primary' />
      <section className='album__hero'>
        <div className='album__hero--image-container'>
          <AlbumImage src={ALBUMS[myAlbum].image} alt={`${ALBUMS[myAlbum].title} cover`}/>
        </div>
        <div className="album__hero--side">
          <p className="album__hero--counter">{conjugate(Object.values(ALBUMS[myAlbum].songs).length)}</p>
          <p className="album__hero--date">{ALBUMS[myAlbum].releaseDate.getFullYear()}</p>
          {AlbumButton}
        </div>
      </section>
      <p className='title'>Utwory</p>
      <hr className='bg-primary' />
      <section className='album__song-list'>
        {Object.values(ALBUMS[myAlbum].songs).map((song) => (
          <>
            <button className='album__song--container'  type='button' onClick={() => handleSongClick(song)}>
              <p>{`${song.title} ${song?.extraText ?? ''}`}</p>
              <span className='release-container-arrow'>
                {'>'}
              </span>
            </button>
            <hr className='bg-primary' />
          </>
          )
        )}
      </section>
    </div>
  );
}

export default AlbumPage;
