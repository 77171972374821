import { Song } from 'types/releaseTypes';

const Dziekczynienie: Song = Object.freeze({
    title: 'Dziękczynienie',
    author: 'Janusz Bigda',
    isCover: true,
    tempo: 62,
    meter: '',
    key: '..',
    releaseDate: new Date(2020, 9, 14),
    youtubeLink: 'https://youtu.be/AXHWOwB6U-M',
  });

export default Dziekczynienie;