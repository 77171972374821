import { textify } from 'store/text';
import { Song } from 'types/releaseTypes';

const NalezeDoCiebie: Song = Object.freeze({
    title: 'Należę do Ciebie',
    author: 'Łukasz Skotnicki',
    isCover: false,
    tempo: 85,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2022, 12, 5),
    spotifyLink: 'https://open.spotify.com/track/0AnUBlWK14eUObTo3LqLG3?si=ff5c5fc395394756',
    textFile: 'naleze-do-ciebie',
    textLeft: textify(`
      PRZYCHODZĘ DZIŚ DO RAMION TWYCH
      CHCĘ WYZNAĆ, ŻE JESTEM TWÓJ
      ODDAJĘ CI KAŻDY Z MYCH DNI
      WSZYSTKO, CO MAM, TWOIM JEST
      
      NALEŻĘ DO CIEBIE, NALEŻĘ DO CIEBIE
      NALEŻĘ DO CIEBIE, JEZU
      
      JESTEM TWÓJ
      TERAZ I ZAWSZE DLA CIEBIE BĘDĘ ŻYŁ
      JESTEM TWÓJ
      BEZ RESZTY ODDANY TOBIE, MÓJ PANIE 
    `)
  });

export default NalezeDoCiebie;