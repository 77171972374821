import { Song } from 'types/releaseTypes';

const SinkingDeep: Song = Object.freeze({
    title: 'Sinking Deep',
    extraText: '(Głęboko tak)',
    author: 'Hillsong Young & Free',
    isCover: true,
    tempo: 70,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2019, 9, 11),
    youtubeLink: 'https://youtu.be/3JcDBrbdRno',
  });

export default SinkingDeep;