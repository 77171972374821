import { KeyedBreak } from "store/text";
import churchInfo from './contactInfo';

export default {
  imageUrl: 'images/side/section_about_XY.jpg',
  text: {
    title: 'XY Uwielbienie',
    text: [
      `XYU to grupa przyjaciół ze ${churchInfo.expandedNameConjugated} w ${churchInfo.cityConjugated}. ` +
      'Kochamy Boga i chcemy poznawać Go głębiej. Naszym marzeniem ' +
      'jest, by uwielbienie płynące z kościoła było jak ożywcza rzeka, która ' +
      'zmienia rzeczywistość i przynosi owoce w postaci uwolnienia, ' +
      'uzdrowienia i zbawienia. Chcemy, by to, co robimy, oddawało Bogu ' +
      'chwałę, a Duch Święty realnie objawiał się w swojej mocy.',
      KeyedBreak(),
      KeyedBreak(),
      <b key='what_we_want_bold'>
        Nie chcemy jedynie tworzyć kolejnego projektu muzycznego – pragniemy głębi, z której wypływać będą nowe dźwięki
        i teksty.{' '}
      </b>,
      'W 2018 roku założyliśmy kanał na YT, na którym umieszczamy zarówno autorskie ' +
      'utwory, jak i covery. Stawiamy na wysoką jakość pod względem muzycznym, wizualnym i produkcyjnym.',
    ],
  },
};
