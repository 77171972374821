import { textify } from "store/text";
import { Song } from "types/releaseTypes";

const SpiewamChwala: Song = Object.freeze({
    title: 'Śpiewam chwała',
    author: 'Jan Przepiórkowski',
    isCover: false,
    tempo: 96,
    meter: '6/8',
    key: 'F',
    releaseDate: new Date(2021, 12, 5),
    spotifyLink: 'https://open.spotify.com/track/0YmRu9hL8O51JcjqlgGYXS?si=e84fcc027a37493d',
    textFile: 'spiewam-chwala',
    chordsFile: 'spiewam-chwala-F',
    textLeft: textify(`
    CAŁY ŚWIAT
    NIECH ODDA MU CZEŚĆ

    BOGU, KTÓRY ZBAWIŁ ŚWIAT
    KTÓRY ZA STWORZENIE ŻYCIE DAŁ

    ŚPIEWAM CHWAŁA
    CHWAŁA, CHWAŁA, CHWAŁA

    BOGU, KTÓRY ZBAWIŁ ŚWIAT
    KTÓRY, ŻEBYM ŻYŁ, SWOJE ŚWIĘTE ŻYCIE DAŁ
    `)
  });

export default  SpiewamChwala;