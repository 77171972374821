import { Single } from 'types/releaseTypes';
import songs from './songs';

export type Singles = {
  [index: string]: Single;
};

export const singles: Singles = Object.freeze({
  // url tag should be the same as object key
  atTheCross: {
    ...songs.AtTheCross,
    image: 'at-the-cross.jpg',
  },
  czekamNaCiebie: {
    ...songs.CzekamNaCiebie,
    image: 'czekam-na-ciebie.jpg',
  },
  dawcaDrogi: {
    ...songs.DawcaDrogi,
    image: 'dawca-drogi.jpg',
  },
  dziekczynienie: {
    ...songs.Dziekczynienie,
    image: 'dziekczynienie.jpg',
  },
  graceToGrace: {
    ...songs.GraceToGrace,
    image: 'grace-to-grace.jpg',
  },
  inChristAlone: {
    ...songs.InChristAlone,
    image: 'in-christ-alone.jpg',
  },
  itIsSo: {
    ...songs.ItIsSo,
    image: 'it-is-so.jpg',
  },
  jezusNajwyzszeImie: {
    ...songs.JezusNajwyzszeImie,
    image: 'jezus-najwyzsze-imie.jpg',
  },
  newWine: {
    ...songs.NewWine,
    image: 'new-wine.jpg',
  },
  niePatrzeWstecz: {
    ...songs.NiePatrzeWstecz,
    image: 'nie-patrze-wstecz.jpg',
  },
  noLongerSlaves: {
    ...songs.NoLongerSlaves,
    image: 'no-longer-slaves.jpg',
  },
  onlyWannaSing: {
    ...songs.OnlyWannaSing,
    image: 'only-wanna-sing.jpg',
  },
  pelenLaski: {
    ...songs.PelenLaski,
    image: 'pelen-laski.jpg',
  },
  podroznik: {
    ...songs.Podroznik,
    image: 'podroznik.jpg',
  },
  proszePowiedzPanie: {
    ...songs.ProszePowiedzPanie,
    image: 'prosze-powiedz-panie.jpg'
  },
  sinkingDeep: {
    ...songs.SinkingDeep,
    image: 'sinking-deep.jpg',
  },
  youDeserveIt: {
    ...songs.YouDeserveIt,
    image: 'you-deserve-it.jpg',
  },
} as const);
