import { ALBUMS } from "data/releases/albums";
import { Album, isAlbum, Single, Song } from "types/releaseTypes";

export const titleToUrl = (release: Album | Single | Song | undefined) => release === undefined? undefined : 
    release.title.toLocaleLowerCase()
        .replace(/(\s*\(.*\))|,/g, '')
        .replace(/[\s\u00A0]+/gu, '-')
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .replace('ł', 'l');

export const prepareUrl = (release: Album | Single) =>
    encodeURI(`/songs/${isAlbum(release)? 'album' : 'single'}/${titleToUrl(release)}`)

export const getAlbumFromId = (albumId: string | undefined) =>  Object.entries(ALBUMS).find(([, album]) => titleToUrl(album) === albumId)?.[0] as keyof typeof ALBUMS | undefined;

// eslint-disable-next-line import/no-dynamic-require, global-require
export const getTextPdfUrl = (name: string | undefined) => name !== undefined? require(`../assets/texts/${name}.pdf`) : name;

// eslint-disable-next-line import/no-dynamic-require, global-require
export const getChordsPdfUrl = (name: string | undefined) => name !== undefined? require(`../assets/chords/${name}.pdf`) : name;
 
export function AlbumImage({src, className, alt}: {src: string, className?: string, alt?: string}){
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return <img src={require(`../assets/images/albums/${src}`)} alt={alt ?? 'album cover'} className={className}/>;
}

export function SingleImage({src, className, alt}: {src: string, className?: string, alt?: string}){
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return <img src={require(`../assets/images/songs/${src}`)} alt={alt ?? 'single cover'} className={className}/>;
}