import { useCallback } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { prepareUrl, AlbumImage, SingleImage } from "store/url";
import { Album, isAlbum, Single } from "types/releaseTypes";
import withFullscreenImage from "./withFullscreenImage";

function NewestReleaseBox({release, color}: {release: Single | Album, color: 'primary' | 'secondary'}) {
    const navigate = useNavigate();
  
    const handleSingleClick = useCallback(() => {
      navigate(prepareUrl(release));
    }, [navigate, release])
  
    return (
    <div className={`new-single__content${isAlbum(release)?' album': ''}`}>
        <div>
          {isAlbum(release)? 
          <AlbumImage src={release.image} alt='black and white shed in mountains'/> :
          <SingleImage src={release.image} alt='black and white shed in mountains'/> }
        </div>
        <div className="new-single__content__side">
          <div>
              <h4>{isAlbum(release)?'Nowa płyta': 'Nowy utwór'}</h4>
              <h1>{release.title}</h1>
          </div>
          <Button variant={color === 'primary'?'outline-dark':'outline-light'} onClick={handleSingleClick}>
            Słuchaj teraz
          </Button>
        </div>
      </div>
  );
  }

  export default withFullscreenImage(NewestReleaseBox);