import { Song } from "types/releaseTypes";

const GraceToGrace: Song = Object.freeze({
    title: 'Grace to Grace',
    extraText: '(Łaski Cud)',
    author: 'Hillsong Worship',
    isCover: true,
    tempo: 80,
    meter: '4/4',
    key: 'C',
    releaseDate: new Date(2018, 5, 21),
    youtubeLink: 'https://www.youtube.com/watch?v=4wGRku32Ua4',
    spotifyLink: 'https://open.spotify.com/track/588i1nkEpxaFtEQ7tvTMYs?si=130d3eb64b9e492a'
  });

  export default GraceToGrace;