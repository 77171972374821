import { Song } from 'types/releaseTypes';

const AtTheCross: Song = Object.freeze({
    title: 'At the cross',
    extraText: '(Ty Panie znasz mnie)',
    author: 'Hillsong Worship',
    isCover: true,
    tempo: 70,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2020, 8, 31),
    youtubeLink: 'https://youtu.be/ORaIxU2tNCY',
  });

export default AtTheCross;