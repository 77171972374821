import { SongCollection } from "types/releaseTypes";
import AtTheCross from "./atTheCross";
import CzekamNaCiebie from "./czekamNaCiebie";
import DawcaDrogi from "./dawcaDrogi";
import Dziekczynienie from "./dziekczynienie";
import FuturePast from "./futurePast";
import GodOfWonders from "./godOfWonders";
import GraceToGrace from "./graceToGrace";
import HallelujahHereBelow from "./hallelujahHereBelow";
import InChristAlone from "./inChristAlone";
import ItIsSo from "./itIsSo";
import Jeremiasz29 from "./jeremiasz29";
import JezusNajwyzszeImie from "./jezusNajwyzszeImie";
import MoimPokojem from "./moimPokojem";
import NalezeDoCiebie from "./nalezeDoCiebie";
import NeverLost from "./neverLost";
import NewWine from "./newWine";
import NiePatrzeWstecz from "./niePatrzeWstecz";
import NoLongerSlaves from "./noLongerSlaves";
import OnlyWannaSing from "./onlyWannaSing";
import PelenLaski from "./pelenLaski";
import Podroznik from "./podroznik";
import ProszePowiedzPanie from "./proszePowiedzPanie";
import Selah from "./selah";
import SinkingDeep from "./sinkingDeep";
import SpiewamChwala from "./spiewamChwala";
import Surrounded from "./surrounded";
import Tremble from "./tremble";
import UciszSie from "./uciszSie";
import UfacBede from "./ufacBede";
import WszystkieMojeMysli from "./wszystkieMojeMysli";
import YouDeserveIt from "./youDeserveIt";

const songs: SongCollection = Object.freeze({
  GraceToGrace,
  WszystkieMojeMysli,
  UfacBede,
  SpiewamChwala,
  Selah,
  Jeremiasz29,
  ProszePowiedzPanie,
  MoimPokojem,
  NalezeDoCiebie,
  NiePatrzeWstecz,
  PelenLaski,
  CzekamNaCiebie,
  SinkingDeep,
  JezusNajwyzszeImie,
  AtTheCross,
  OnlyWannaSing,
  InChristAlone,
  NoLongerSlaves,
  Tremble,
  DawcaDrogi,
  Podroznik,
  Dziekczynienie,
  ItIsSo,
  NewWine,
  NeverLost,
  FuturePast,
  GodOfWonders,
  UciszSie,
  Surrounded,
  HallelujahHereBelow,
  YouDeserveIt,
} as const);

export default songs;

