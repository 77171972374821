import { Button, Col, Modal } from 'react-bootstrap';
import { useState } from 'react';

import 'styles/components/MainPage/TextColumnWithTitle.scss';
import { TextBlockData } from 'types/componentData';

const maxVW = 12; // 1vw for col height of 510px
export const variantSize = (n: number) => `min(${n}vw, ${n * maxVW}px)`;

const titleAndTextDiv = (title: string, text: string | (string | JSX.Element)[], fg: string) => (
  <div style={{ padding: `0.5rem ${variantSize(6.8)}`, width: 'fit-content', height: 'fit-content' }}>
    <p className='title'>{title}</p>
    <hr className={`bg-${fg}`} />
    <p style={{ padding: '10px 0px'}}>{text}</p>
  </div>
);

function TextColumnWithTitle({
  bgColor,
  fgColor,
  width,
  data,
  isLeft,
}: {
  bgColor: 'light' | 'dark' | 'primary' | 'secondary';
  fgColor: 'light' | 'dark' | 'primary' | 'secondary';
  width?: string;
  data: TextBlockData;
  isLeft?: boolean;
}) {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleHide = () => setShowModal(false);

  const expandedTextModal = () =>
    data.expandedData && (
      <Modal show={showModal} onHide={handleHide} centered dialogClassName='modal-90w'>
        <div className={`bg-${bgColor} text-${fgColor}`}>
          <Modal.Header
            closeVariant={fgColor === 'light' ? 'white' : undefined}
            closeButton
            style={{ border: '0', padding: '1rem 1rem 0 1rem' }}
          />
          <Modal.Body className='modal-padding'>
            {titleAndTextDiv(data.expandedData.title ?? data.title, data.expandedData.text, fgColor)}
          </Modal.Body>
        </div>
      </Modal>
    );
  return (
    <Col className={`bg-${bgColor} text-${fgColor} d-flex align-items-center text-col ${width ?? ''}`}>
      <div className='fit' style={{ paddingLeft: isLeft ? '0' : 'none' }}>
        <div className='d-flex justify-content-between'>
          <p className='title'>{data.title}</p>
          {data.expandedData && (
            <Button variant={`outline-${fgColor}`} onClick={handleShow}>
              Więcej
            </Button>
          )}
        </div>
        <hr className={`bg-${fgColor}`} />
        <p style={{ padding: '0.5rem 0px' }}>{data.text}</p>
      </div>
      {expandedTextModal()}
    </Col>
  );
}

export default TextColumnWithTitle;
