import { Song } from 'types/releaseTypes';
import { textify } from 'store/text';

const Jeremiasz29: Song = Object.freeze({
    title: 'Jeremiasz 29',
    author: 'Biblia, Jer 29,11-14a',
    isCover: false,
    tempo: NaN,
    meter: '-',
    key: '-',
    releaseDate: new Date(2021, 12, 5),
    spotifyLink: 'https://open.spotify.com/track/5ZWKXn7BiS1wkX4OxTYOfi?si=33eecdc70ee34f81',
    textLeft: textify(`
      Ja wiem, jakie wiążę z wami plany 
      — oświadcza PAN. — To plany o pokoju,
      a nie o niedoli. 
      Chcę dać wam szczęśliwą przyszłość
      i uczynić was ludźmi nadziei. 
      Wysłucham was, gdy będziecie Mnie
      wzywać i zbliżać się do Mnie w modlitwie.
      Znajdziecie Mnie, gdy będziecie Mnie szukać. 
      Tak! Gdy będziecie Mnie szukać 
      całym swoim sercem, pozwolę wam się znaleźć.
      Jeremiasza 29:11 - 14a SNP
    `)
  });

export default Jeremiasz29;