import { Song } from "types/releaseTypes";
import { textify } from 'store/text';

const ProszePowiedzPanie: Song = Object.freeze({
    title: 'Proszę powiedz, Panie',
    author: 'Gosia Kudła',
    isCover: false,
    tempo: 85.5,
    meter: '4/4',
    key: 'D',
    releaseDate: new Date(2021, 12, 5),
    youtubeLink: 'https://youtu.be/Cb4349J_734',
    spotifyLink: 'https://open.spotify.com/track/5gYxWf0uXqr5EvdIeOlpXa?si=8f2300386ca04c3a',
    textFile: 'prosze-powiedz-panie',
    textLeft: textify(`
      TY WIESZ O UŚMIECHACH
      TY WIESZ O MYCH GRZECHACH
      TY WIESZ O POTKNIĘCIACH
      ILE RAZY UPADAŁAM TWARZĄ W PIACH

      TY ZNASZ DOBRE MIEJSCE
      TY ZNASZ MOJE SERCE
      TY ZNASZ IMIĘ ME
      PANIE WSZYSTKO O MNIE WIESZ

      PROSZĘ POWIEDZ, PANIE
      JESTEM W TWOIM PLANIE?
      KIEDY ŚWIAT SIĘ WALI
      CZY TWOJE ŚCIEŻKI ZNAM?
      CAŁYM SERCEM SZUKAM
      WIEM, NIE ZAPOMNIAŁEŚ
      BO TY OBIECAŁEŚ -
      „ZNALEŹĆ SIEBIE DAM”
    `),
    textRight: textify(`
      TWÓJ PLAN O POKOJU, TO NIE NIEDOLI STAN
      NAUCZ MNIE NADZIEI, BY BEZ LĘKU PATRZEĆ W DAL
      NIE TYLKO ZAPISZ MOJE DNI
      ALE WPISZ SIĘ W KAŻDY Z NICH

      NIE MA MIEJSCA NA MÓJ LĘK
      GDY W MODLITWIE ZBLIŻAM SIĘ
      WZYWAM IMIĘ TWE

      PROSZĘ POWIEDZ, PANIE
      JESTEM W TWOIM PLANIE?
      KIEDY ŚWIAT SIĘ WALI
      CZY TWOJE ŚCIEŻKI ZNAM?
      CAŁYM SERCEM SZUKAM
      WIEM, NIE ZAPOMNIAŁEŚ
      BO TY OBIECAŁEŚ -
      „ZNALEŹĆ SIEBIE DAM”
    `),
  });

export default ProszePowiedzPanie;