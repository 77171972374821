import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import ReleaseBox from 'components/ReleaseBox';
import 'styles/components/MainPage/Music.scss';
import { singles } from 'data/releases/singles';
import { scrollLinks } from 'data/pageLinks';
import { ALBUMS, LATEST_ALBUM } from 'data/releases/albums';
import { Single } from 'types/releaseTypes';

function Music() {
  const navigate = useNavigate();
  const handleDiscography = () => navigate('/songs');
  const displayedSingles: Single[] = [singles.graceToGrace, singles.niePatrzeWstecz];
  return (
    <div
      id={scrollLinks.Music.ref.split('#')[1]}
      className='text-light no-gutters music-container container-fluid my-margin bg-dark'
    >
      <div className='d-flex justify-content-between title-div'>
        <p className='title'>Muzyka</p>
        <Button variant='outline-light' onClick={handleDiscography}>
          Cała Dyskografia
        </Button>
      </div>
      <hr />
      <Container className='d-flex single-container' style={{ width: '100%', padding: '0 calc(var(--padding-width) * 0.5)' }} fluid>
        <Col style={{ width: '6.6em', flexBasis: 'auto' }}>
          <ReleaseBox release={ALBUMS[LATEST_ALBUM]} secondary/>
        </Col>
        <Col>
          {displayedSingles.map((single) => (
            <Row style={{ height: 'fit-content', margin: '0' }} key={`${single.title}_main_page_single`}>
              <ReleaseBox release={single} secondary/>
            </Row>
          ))}
        </Col>
      </Container>
    </div>
  );
}

export default Music;
