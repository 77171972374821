import { ALBUMS } from 'data/releases/albums';
import { useParams  } from 'react-router-dom';
import { titleToUrl, AlbumImage, SingleImage, getAlbumFromId, getTextPdfUrl, getChordsPdfUrl } from 'store/url';
import { Song } from 'types/releaseTypes';
import { singles } from 'data/releases/singles';
import { variantSize } from './MainPage/components/TextColumnWithTitle';
import 'styles/pages/SongPage.scss';
import NotFoundPage from './NotFoundPage';

function GenerateSection({
  title, 
  buttonTitle, 
  href,
  secondButton 
} : {
    title: string, 
    buttonTitle: string, 
    href?: string,
    secondButton?: {
      title: string, 
      href: string,
    }
}) {
  return (
    <>
      <div className='section__header'>
        <p className="title">{title}</p>
        <div>
          {!!secondButton && <a className={`btn btn-outline-dark${href?' extra-button': ''}`} href={secondButton.href} role="button" target="_blank" rel='noreferrer'>
            {secondButton.title}
          </a>}
          {!!href && <a className='btn btn-outline-dark' href={href} role="button" target="_blank" rel='noreferrer'>
            {buttonTitle}
          </a>}
        </div>
      </div>
      <hr/>
    </>
  );
}
  const displayPage = (image: JSX.Element, song: Song, album?: string) => (
    <div className='my-margin song__container' style={{ padding: `0.5rem ${variantSize(6.8)}` }}>
      <hr/>
      <section className='song__hero'>
        <div className={`song__hero--image-container${album? ' album__cover' : ''}`}>
          {image}
        </div>
        <div className="song__hero--side">
          <p className="title">{song.title}</p>
          {song.extraText && <p className="title extra">{song.extraText}</p>}
          <div className="song__hero--details">
            <div>
              {!!album && <p><b>Album</b> {album}</p>}
              <p><b>{song.isCover? 'Oryginał': 'Autor'}</b> {song.author}</p>
            </div>
            <div>
              <p><b>Tempo</b> {Number.isNaN(song.tempo)? '-' : song.tempo}</p>
              <p><b>Metrum</b> {song.meter}</p>
              <p><b>Tonacja</b> {song.key}</p>
            </div>
          </div>
        </div>
      </section>
      {(song.textLeft || song.textFile) && <>
        <section className="song__text__file">
          <GenerateSection title='Tekst' buttonTitle='Pobierz' href={getTextPdfUrl(song.textFile)} 
          />
        </section>
        <section className='song__text'>
          <div className={song.textRight? 'grid-col-2': ''}>
            <p>
              {song.textLeft}
            </p>
            {song.textRight && <p>
              {song.textRight}
            </p>}
          </div>
          <hr/>
        </section>
      </>}
      {song.chordsFile && <section className="song__chords">
        <GenerateSection title='Akordy' buttonTitle='Pobierz' href={getChordsPdfUrl(song.chordsFile)} />
      </section>}
      {!!(song.youtubeLink || song.spotifyLink) && <section className="song__video">
      <GenerateSection 
        title='Posłuchaj' 
        buttonTitle='Youtube' 
        href={song.youtubeLink} 
        secondButton={song.spotifyLink? 
          {
            title: 'Spotify',
            href: song.spotifyLink
          } : 
          undefined
        }
      />
      </section>}
    </div>
  );

function SongPage() {
  const { albumId, songId } = useParams();
  const myAlbum = getAlbumFromId(albumId);
  if(myAlbum) {
    const mySong = Object.values(ALBUMS[myAlbum].songs).find((song) => titleToUrl(song) === songId);
    if(!mySong) {
      return <NotFoundPage/>;
    }
    return displayPage(<AlbumImage src={ALBUMS[myAlbum].image} alt={`${ALBUMS[myAlbum].title} cover`}/>, mySong, ALBUMS[myAlbum].title);
  }
  const mySong = Object.values(singles).find((song) => titleToUrl(song) === songId);
  if(!mySong) {
    return <NotFoundPage/>;
  }
  return displayPage(<SingleImage src={mySong.image} alt={`${mySong.title} cover`}/>, mySong);
}

export default SongPage;
