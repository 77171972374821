import { Song } from 'types/releaseTypes';

const DawcaDrogi: Song = Object.freeze({
    title: 'Dawca Drogi',
    author: 'Aleksandra Bajak',
    isCover: true,
    tempo: 132,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2020, 8, 17),
    youtubeLink: 'https://youtu.be/AzxuCTle_Y4',
  });

export default DawcaDrogi;