import { Album } from 'types/releaseTypes';
import songs from './songs';
import Pick from 'lodash/pick';

const Poludnie: Album = Object.freeze({
  title: 'Południe',
  releaseDate: new Date(2021, 12, 5),
  image: 'poludnie.jpg',
  spotifyLink: 'https://open.spotify.com/album/41FWIxbLuIT6ZoDNJiVDvg',
  songs: Pick(songs, [
    'WszystkieMojeMysli',
    'UfacBede',
    'SpiewamChwala',
    'Selah',
    'Jeremiasz29',
    'ProszePowiedzPanie',
    'MoimPokojem',
    'NalezeDoCiebie',
    'NiePatrzeWstecz',
    'PelenLaski',
    'CzekamNaCiebie',
  ])
});

const UfacBede: Album = Object.freeze({
  title: 'Ufać Będę',
  releaseDate: new Date(2020, 12,7),
  image: 'ufac-bede.png',
  spotifyLink: 'https://open.spotify.com/album/0eARRIRdmUUczAwF1RZfOC',
  youtubeLink: 'https://www.youtube.com/watch?v=FY9BDqaUJJE',
  songs: Pick(songs, [
    'OnlyWannaSing',
    'NeverLost',
    'FuturePast',
    'HallelujahHereBelow',
    'UfacBede',
    'UciszSie',
    'Surrounded',
    'GraceToGrace',
    'GodOfWonders',
  ])
});

export const ALBUMS = Object.freeze({
  Poludnie,
  UfacBede
});

export const LATEST_ALBUM = Object.freeze(Object.entries(ALBUMS).reduce((acc, album) => {
  if(acc[1].releaseDate > album[1].releaseDate) 
    return acc;
  return album;
})[0] as keyof typeof ALBUMS);
