import { Song } from 'types/releaseTypes';

const Surrounded: Song = Object.freeze({
    title: 'Surrounded',
    extraText: '(Tak wygrywam moje walki)',
    author: 'Elyssa Smith',
    isCover: true,
    tempo: 68.5,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2021, 1, 21),
    youtubeLink: 'https://youtu.be/9snp6zR_d-4',
    spotifyLink: 'https://open.spotify.com/track/23EQaT9ydZIus6rdtO2iQ1?si=ab8e4085a71e4c8a',
  });

export default Surrounded;