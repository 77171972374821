import { Song } from 'types/releaseTypes';

const OnlyWannaSing: Song = Object.freeze({
    title: 'Only wanna sing',
    extraText: '(Tylko śpiewać chcę)',
    author: 'Hillsong Young & Free',
    isCover: true,
    tempo: 65,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2018, 1, 25),
    youtubeLink: 'https://youtu.be/ZJ97frFlIfQ',
    spotifyLink: 'https://open.spotify.com/track/2tNZKZDOwddvECliJYy8Gh?si=08fadb4360e54991',
  });

export default OnlyWannaSing;