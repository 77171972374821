export interface Song {
  title: string;
  extraText?: string;
  author: string;
  releaseDate: Date | null;
  isCover: boolean; //
  tempo: number;
  textFile?: string;
  textLeft?: (string | React.ReactNode)[] | string
  textRight?: (string | React.ReactNode)[] | string
  youtubeLink?: string;
  spotifyLink?: string;
  chordsFile?: string;
  meter: string;
  key: string;
}

export interface Album {
  image: string;
  title: string;
  releaseDate: Date;
  songs: SongCollection;
  spotifyLink?: string;
  youtubeLink?: string;
}

export type SongCollection = {
  [key: string]: Song;
};

export interface Single extends Song {
  image: string;
}

export function isAlbum(obj: any): obj is Album {
  return (obj as Album).songs !== undefined;
}
