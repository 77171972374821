import { Row, Col } from 'react-bootstrap';

import 'styles/components/MainPage/ImageAndTextBlock.scss';
import { ImageAndTextBlockData } from 'types/componentData';
import TextColumnWithTitle from './TextColumnWithTitle';


function ImageAndTextBlock({ data, isTextLeft }: { data: ImageAndTextBlockData; isTextLeft: boolean }) {
  const themeColour = isTextLeft ? 'light' : 'dark';
  const fgColour = isTextLeft ? 'dark' : 'light';

  const texCol = <TextColumnWithTitle bgColor={themeColour} fgColor={fgColour} data={data} isLeft={isTextLeft} />;
  // eslint-disable-next-line import/no-dynamic-require, global-require
  const imageUrl = require(`assets/${data.imageUrl}`);
  const imgCol = (
    <Col
      className='side-image'
      style={{
        
        backgroundImage: `url(${imageUrl})`,
      }}
    />
  );

  return (
    <Row id={data.id.split('#')[1]} className={`image-and-text-row${isTextLeft?' reverse':''}`}>
      {imgCol}
      {texCol}
    </Row>
  );
}

export default ImageAndTextBlock;
