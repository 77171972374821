import { Song } from 'types/releaseTypes';

const GodOfWonders: Song = Object.freeze({
    title: 'God of wonders',
    extraText: '(Stworzycielu świata)',
    author: 'Steve Hindalong & Marc Byrd',
    isCover: true,
    tempo: 106,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2020, 12, 21),
    youtubeLink: 'https://youtu.be/-w88jIw6NlI',
    spotifyLink: 'https://open.spotify.com/track/0PSpueWuOajF6vtPLTRzn9?si=68838495bd6f489a'
  });

export default GodOfWonders;