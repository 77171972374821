import { textify } from 'store/text';
import { Song } from 'types/releaseTypes';

const InChristAlone: Song = Object.freeze({
    title: 'In Christ alone',
    extraText: '(Nadzieję odnalazłem w Nim)',
    author: 'Keith Getty & Stuard Towned',
    isCover: true,
    tempo: 63,
    meter: '3/4',
    key: '..',
    releaseDate: new Date(2020, 4, 2),
    youtubeLink: 'https://youtu.be/iJAuzTtuyxI',
    textFile: 'in-christ-alone',
    textLeft: textify(`
      Nadzieję odnalazłem w Nim 
      On moją siłą światłem mym
      On fundamentem twierdzą mą
      Co zniesie najstraszniejszy sztorm

      Miłości szczyt pokoju Bóg
      Uciszy strach nasyci głód 
      On pocieszyciel życia sens
      W Jego miłości schronię się

      W Chrystusie Bóg objawił się
      W bezbronnym dziecku w Betlejem
      Na ziemię zszedł by zbawić nas 
      By przebaczenia nastał czas


      Na krzyżu zmarł za każdy grzech
      By uspokoić Boży gniew 
      Uwolnił mnie z ciężaru win 
      W śmierci Chrystusa żyję dziś
    `),
    textRight: textify(`
    Przygasła światłość nastał chłód 
    Gdy ciało zakrył ciemny grób 
    Lecz krótko trwała złego moc 
    Zmartwychwstał Pan minęła noc

    Przez swoją śmierć uwolnił mnie 
    Umierać z Nim i żyć z Nim chcę
    Przekleństwo zdjął odkupił z win 
    On we mnie jest ja jestem w Nim 

    W Nim nawet śmierć
    Niestraszna jest 
    Chrystusa moc ochrania mnie
    Od pierwszych chwil po życia kres 
    Wytycza drogi mojej bieg

    Ni grzechu zło ni ludzki sąd 
    Nie wydrze mnie z Chrystusa rąk
    A gdy mnie weźmie gdzie mój dom 
    Z radością pójdę tam gdzie On
    `)
  });

export default InChristAlone;