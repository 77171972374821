import { Song } from 'types/releaseTypes';

const NeverLost: Song = Object.freeze( {
    title: 'Never lost',
    extraText: '(Nigdy nie zawiodłeś)',
    author: 'Elevation Worship',
    isCover: true,
    tempo: 62,
    meter: '6/2',
    key: '..',
    releaseDate: new Date(2020, 11, 9),
    youtubeLink: 'https://youtu.be/BRFowWMzjCE',
    spotifyLink: 'https://open.spotify.com/track/6UDZheQ2lKtaiMSFAponW8?si=3c241e9039c1442e'
  });

export default NeverLost;