import { Song } from 'types/releaseTypes';
import { textify } from 'store/text';

const WszystkieMojeMysli: Song = Object.freeze({
    title: 'Wszystkie moje myśli',
    author: 'Dawid Wysoczyński',
    isCover: false,
    tempo: 80,
    meter: '4/4',
    key: 'C',
    releaseDate: new Date(2021, 12, 5),
    spotifyLink: 'https://open.spotify.com/track/5pok17h2xsaP0wF4Ue3e38?si=8ea3276a1e414db4',
    textFile: 'wszystkie-moje-mysli',
    chordsFile: 'wszystkie-moje-mysli-C',
    textLeft: textify(`
      WSZYSTKIE MOJE MYŚLI
      KAŻDĄ TROSKĘ PRAGNĘ ZŁOŻYĆ POD STOPY TWE
      TOBIE CHCĘ DZIĘKOWAĆ  
      W TWOJE RĘCE OFIAROWAĆ SERCE ME
      BO TY

      OBMYŁEŚ MOJE WINY
      WIDZIAŁEŚ WTEDY MNIE
      GDY GWOŹDZIE GRZECHU PRZEBIŁY CIĘ
      KRZYŻ STAŁ SIĘ TWYM CIERPIENIEM
      ZBAWIENIE DAŁEŚ MI
      ZBAWIENIE DZIĘKI PRZELANEJ KRWI

      WSZYSTKIE MOJE MYŚLI
      KAŻDĄ TROSKĘ PRAGNĘ ZŁOŻYĆ POD STOPY TWE
      A WSZYSTKO, CZEGO CHCĘ
      TO BY CI DZIĘKOWAĆ
      W TWOJE RĘCE OFIAROWAĆ SERCE ME
      DLATEGO, ŻE

      OBMYŁEŚ MOJE WINY
      WIDZIAŁEŚ WTEDY MNIE
      GDY GWOŹDZIE GRZECHU PRZEBIŁY CIĘ
      KRZYŻ STAŁ SIĘ TWYM CIERPIENIEM
      ZBAWIENIE DAŁEŚ MI
      A WSZYSTKO DZIĘKI TEMU, ŻE TY
    `)
  });

export default WszystkieMojeMysli;