import { useRef, useState, useEffect, useMemo } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Single, Album, isAlbum } from 'types/releaseTypes';
import 'styles/components/ReleaseBox.sass';
import { AlbumImage, prepareUrl, SingleImage } from '../store/url';

function ReleaseBox({ release, secondary }: { release: Single | Album, secondary?: boolean }) {
  const [paragraphHeight, setParagraphHeight] = useState(44);
  const titleRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleResize = () => {
    if (titleRef.current) {
      setParagraphHeight(titleRef.current.clientHeight);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleRef.current]);

  const handleClick = () => {
    navigate(prepareUrl(release));
  };

  const releaseType = useMemo(() => {
    if (isAlbum(release)) return 'Płyta';
    if (release.isCover) return 'Cover';
    return 'Utwór';
  }, [release]);

  return (
    <Container fluid className='release-container'>
      <Row style={{ width: '100%', height: `calc(100% - ${paragraphHeight}px)`, margin: 'auto 0' }}>
        {isAlbum(release)? 
          <AlbumImage src={release.image} alt={release.title} className='release-container-image'/> :
          <SingleImage src={release.image} alt={release.title} className='release-container-image'/> }
      </Row>
      <Row ref={titleRef} className='release-container-title'>
        <p>
          <b>{releaseType}</b>
          <br />
          {release.title}
        </p>
        <button onClick={handleClick} className={`release-container-arrow${secondary? '--secondary': ''}`} type='button'>
          {'>'}
        </button>
      </Row>
    </Container>
  );
}

export default ReleaseBox;
