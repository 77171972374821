import { Song } from 'types/releaseTypes';

const FuturePast: Song = Object.freeze({
    title: 'Future/Past',
    extraText: '(Przyszłość/Przeszłość)',
    author: 'John Mark McMillan',
    isCover: true,
    tempo: 70,
    meter: '6/8',
    key: '..',
    releaseDate: new Date(2020, 11, 23),
    youtubeLink: 'https://youtu.be/FhpQACgK8iI',
    spotifyLink: 'https://open.spotify.com/track/2lLwgcUsAElEYEuWRMgEe1?si=f2fd90a93a784e5b',
  });

export default FuturePast;