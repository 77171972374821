import { Song } from 'types/releaseTypes';

const JezusNajwyzszeImie: Song = Object.freeze({
    title: 'Jezus, najwyższe Imię',
    author: 'Naida Hearn',
    isCover: true,
    tempo: 150,
    meter: '6/8',
    key: 'C',
    releaseDate: new Date(2020, 8, 10),
    youtubeLink: 'https://youtu.be/huXx6Ug3u-g',
  });

export default JezusNajwyzszeImie;