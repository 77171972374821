const churchInfo = {
  name: 'SCh Południe',
  expandedName: 'Społeczność Chrześcijańska Południe',
  expandedNameConjugated: 'Społeczności Chrześcijańskiej Południe',
  street: 'Mińska',
  houseNumber: '65',
  postalCode: '03-828',
  city: 'Warszawa',
  cityConjugated: 'Warszawie',
  bankAccountNumber: '58 1140 2004 0000 3102 7713 4187',
};

export default churchInfo;
