import React, { forwardRef } from "react";
import { Container } from "react-bootstrap";
import 'styles/components/withFullscreenImage.scss';

export type WithFullscreenImageProps = {
    backgroundImage: string;
    color?: 'primary' | 'secondary';
}

function withFullscreenImage<T extends object = object, R = HTMLDivElement>(
    WrappedComponent: React.ComponentType<T>
    ) {
    const ComponentWithImage = forwardRef<R, T & WithFullscreenImageProps>((props, ref) => (
            <Container className={`my-margin withFullscreenImage ${props.color ?? 'primary'}`} fluid  style={{backgroundImage: `url(${props.backgroundImage})`}}>
                <div className="withFullscreenImage__content">
                    <WrappedComponent {...props} ref={ref}/>
                </div>
            </Container>
        ));
    
    return ComponentWithImage;
}

export default withFullscreenImage;