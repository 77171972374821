import { Song } from 'types/releaseTypes';

const Tremble: Song = Object.freeze({
    title: 'Tremble',
    extraText: '(Drżą ciemności)',
    author: 'Mosaic MSC',
    isCover: true,
    tempo: 74,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2020, 8, 3),
    youtubeLink: 'https://youtu.be/E6W40va5i8k',
  });

export default Tremble;