import { Song } from "types/releaseTypes";
import { textify } from '../../../store/text';

const PelenLaski: Song = Object.freeze({
    title: 'Pełen łaski',
    author: 'Tymoteusz Pieszka',
    isCover: false,
    tempo: 85,
    meter: '4/4',
    key: '..',
    releaseDate: new Date(2020, 3, 5),
    youtubeLink: 'https://youtu.be/HD5af_vTnnc',
    spotifyLink: 'https://open.spotify.com/track/3Hc6BLAR3NOKIDYyDxJYPU?si=09cb3a344591436c',
    textFile: 'pelen-laski',
    textLeft: textify(`
      W ciemności pod ciężarem winy
      znalazłeś mnie
      Twa światłość oślepiła serce zgubione
      Więc na kolanach znów przychodzę
      przed łaski tron
      I tęsknię oczekuję Ciebie mój Panie

      Choćbym ciemną szedł doliną
      mego serca głos odbije się
      w ścianach domu Ojca mego
      Słyszysz każdy płacz, westchnienie
      wielką radośći spełnienie
      gdy przychodzisz pełen łaski
      kolejny raz
    `),
    textRight: textify(`
      Swą ręką którą świat stworzyłeś 
      dosięgasz mnie
      Kamienne serce Duchem swoim ożywiasz 
      I jedno czego oczekujesz
      bym wyznał że
      swe życie Ci oddaję
      Prowadźmnie Jahwe
    `)
  });

export default PelenLaski;