import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";


function ScrollToTop({children}: {children: React.ReactNode}){
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}

export default ScrollToTop;