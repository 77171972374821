import { Song } from "types/releaseTypes";
import { textify } from 'store/text';

const NiePatrzeWstecz: Song = Object.freeze({
    title: 'Nie patrzę wstecz',
    author: 'Miłosz Rokacz',
    isCover: false,
    tempo: 70,
    meter: '4/4',
    key: 'C',
    releaseDate: new Date(2019, 5, 16),
    youtubeLink: 'https://youtu.be/-28K8zX1nho',
    spotifyLink: 'https://open.spotify.com/track/1Idx56Vi1AzHxe2CekLJDB?si=fac5f902c0d5444e',
    textFile: 'nie-patrze-wstecz',
    chordsFile: 'nie-patrze-wstecz-C',
    textLeft: textify(`
      ZOSTAWIAM TO, CO ZA MNĄ
      NIE PATRZĘ WSTECZ -
      ODKUPIŁEŚ PRZECIEŻ PRZESZŁOŚĆ MĄ

      NA GRZĄSKIM DNIE LEŻAŁEM
      PÓKI NIE PRZYSZEDŁEŚ PO MNIE
      TERAZ STOJĘ NA SKALE

      MOJE ŻYCIE W TWOICH RĘKACH
      MOJA PRZYSZŁOŚĆ W TWOIM PLANIE
      WIEM, ŻE STOJĘ NA SKALE

      POKONAŁEŚ BÓL
      PRZEBACZYŁEŚ GRZECH
      ZABRAŁEŚ WSTYD
      POKONAŁEŚ ŚMIERĆ
    `)
  });

export default NiePatrzeWstecz;